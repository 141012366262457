/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/52ca2e2f7cd61893-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/0a00df163031819a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/d69b45eeaf23182e-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/270495a4838d7679-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/c7560fd4f938be84-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/471ae18fc3c270a7-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/149d6f443a8e5c54-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/1ccfb5713f38922e-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/524d1c16f5bca1a7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/cc143d9d9581a1dd-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/3b0ac230d404962d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/bec536efdffb4851-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c7fce6e4f84050fd-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/d640c12be4fc4867-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/016998512d65d2ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/670e7515c0ec7969-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/18699a9281f7a2a2-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/e95b56c55e926f76-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/05aedd27f74b307f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5c321730472a6124-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5ea63b290dc24b98-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d1efd2a880aff16c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/5617f5a793538eec-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a730c2098cf2d406-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/10020a8c5cd83a9c-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26e111bc0bbb9793-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1d0c179378730186-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7806ef5df85cda4a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6821bb67d8dbee23-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fe049333cc50d32b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a8f1d802d3a50c22-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d5e97bb9bb0471a9-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bf219fb9b8e9c4d9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2955b29b2e789dbf-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_0685a1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/83c07cea5b6249d7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Fira_Sans_Fallback_0685a1';src: local("Arial");ascent-override: 91.01%;descent-override: 25.79%;line-gap-override: 0.00%;size-adjust: 102.74%
}.__className_0685a1 {font-family: '__Fira_Sans_0685a1', '__Fira_Sans_Fallback_0685a1';font-style: normal
}

